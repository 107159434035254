import React, { useState } from 'react';
import Footer from "../components/Footer";
import '../styles/Order.css';

const Order = () => {
	const TAX_RATE = 0.09;
	const FOUR_STICK_ALBI_PRICE = 20.0;
	const SIX_STICK_ALBI_PRICE = 30.0;

	const products = [
		{
			name: "latinNouns",
			title: "Latin Noun Albi",
			description: "Four double-sided sticks to help you master Latin noun endings.",
			price: FOUR_STICK_ALBI_PRICE,
			thumbnail: "/LatinNounsStix.jpg",
			alt: "Latin Noun Albi thumbnail",
			inStock: true
		},
		{
			name: "latinVerbs",
			title: "Latin Verb Albi",
			description: "Four double-sided sticks to help you master Latin present-stem verb endings.",
			price: FOUR_STICK_ALBI_PRICE,
			thumbnail: "/LatinVerbsStix.jpg",
			alt: "Latin Verb Albi thumbnail",
			inStock: true
		},
		{
			name: "greekNouns",
			title: "Greek Noun Albi",
			description: "Four double-sided sticks to help you get started with Greek nouns.",
			note: "Coming Soon",
			price: FOUR_STICK_ALBI_PRICE,
			thumbnail: "/favicon.jpg",
			alt: "Greek Noun Albi thumbnail",
			inStock: false
		},
		{
			name: "greekVerbs",
			title: "Greek Verb Albi",
			description: "Six double-sided sticks to help you get started with Greek verbs.",
			price: SIX_STICK_ALBI_PRICE,
			thumbnail: "/GreekVerbsStix.jpg",
			alt: "Greek Verb Albi thumbnail",
			inStock: true
		}
	];

	const availableProducts = products.filter(x => x.inStock);

	const names = availableProducts.map(product => product.name);
	const prices = availableProducts.map(product => product.price);
	const quantities = availableProducts.map(product => 0);
	const [orderFormData, setOrderFormData] = useState({
		taxRate: TAX_RATE,
		productNames: names,
		productPrices: prices,
		productQuantities: quantities,
		name: '',
		address: '',
		email: '',
		message: ''
	});

	const [status, setStatus] = useState("Make your product selections and enter your " +
		"shipping information above");

	const handleInputChange = e => {
		const { name, value, type, dataset } = e.target;
		let newValue = value;
		if (type === "select-one") {
			const quantities = orderFormData.productQuantities.slice();
			quantities[dataset.index] = value;
			newValue = quantities;
		}

		setOrderFormData({
			...orderFormData,
			[name]: newValue
		});

		setStatus("Click Submit when you're ready to check out...");
	};

	const calculateTotal = () => {
		let subtotal = 0.0;
		for (let i = 0; i < orderFormData.productPrices.length; i++)
			subtotal += orderFormData.productPrices[i] * orderFormData.productQuantities[i];

		const tax = Math.round(subtotal * orderFormData.taxRate * 100) / 100;
		const total = subtotal + tax;
		return {
			subtotal,
			tax,
			total
		};
	};

	const handleSubmit = async e => {
		e.preventDefault();
		if (total === 0) {
			alert('Please select at least one product to order.');
			return;
		}

		const form = e.target;
		const action = form.action;
		const method = form.method;
		const data = new FormData(form);
		products.filter(product => !product.inStock).forEach(product =>
			data.append(product.name, `Not in stock, price $${product.price}`));

		for (let i = 0; i < orderFormData.productNames.length; i++) {
			data.append(orderFormData.productNames[i],
				`${orderFormData.productQuantities[i]} @ $${orderFormData.productPrices[i].toFixed(2)}`);
		}

		data.append("subtotal", `$${subtotal.toFixed(2)}`);
		data.append("tax", `$${tax.toFixed(2)}`);
		data.append("total", `$${total.toFixed(2)}`);
		data.forEach((value, key) => console.log(`${key}: ${value}`));

		const response = await fetch(action, {
			method: method,
			body: data,
			headers: {
				Accept: "application/json",
			},
		});

		const responseText = await response.text();
		console.log(`Response status: ${response.status}`);
		console.log(`Response text: ${responseText}`);

		if (response.ok) {
			setOrderFormData({
				taxRate: TAX_RATE,
				productNames: names,
				productPrices: prices,
				productQuantities: quantities,
				name: '',
				address: '',
				email: '',
				message: ''
			});

			form.reset();
			setStatus("Thanks for your order! We will get back to you soon.");
		} else {
			setStatus("Oops! There was a problem submitting your form.");
		}
	};

	const { subtotal, tax, total } = calculateTotal();

	return (
		<div className="order-container">
			<h1 className="order-title">
				Albi<span className="trademark">™</span> Handheld Adapters
				<span className="trademark">™</span> Order Page
			</h1>
			<p className="order-subtitle">Please note that this page is under construction.
				While we complete the website, please send your inquiries to albistix@outlook.com
				from the Home page. Thank you for your patience!</p>
			<form className="order-form"
				action="https://formspree.io/f/xrbzddjy"
				method="POST"
				onSubmit={handleSubmit}>
				<ul className="order-form-list">
					{products.map((product, index) => (
						<li key={index} className="order-form-item">
							<div className="order-form-content">
								<div className="order-product-text">
									<h2>{`${product.title} $${product.price.toFixed(2)}`}</h2>
									<p>{product.description}</p>
									<p><em><strong>{product.note}</strong></em></p>
								</div>
								<div className="order-product-thumbnail">
									<img src={product.thumbnail} alt={`${product.title} thumbnail`} />
								</div>
							</div>
						</li>
					))}
				</ul>

				<div className="quantity-cost-section">
					<ul className="quantity-section">
						<h3>Order List</h3>
						{availableProducts.map((product, index) => (
							<li key={index} className="quantity-item">
								<select
										name="productQuantities"
										value={orderFormData.productQuantities[index]}
										data-index={index}
										onChange={handleInputChange}>
									{[...Array(11)].map((i, count) => (
										<option key={count} value={count}>
											{count}
										</option>
									))}
								</select>
								<label>{`${product.title}, $${product.price}`}</label>
							</li>
						))}
					</ul>

					<div name="summary" className="cost-summary-card">
						<h3>Order Summary</h3>
						<div className='summary-line'>
							<p className='Label'>Subtotal:</p>
							<p className='Value'>${subtotal.toFixed(2)}</p>
						</div>
						<div className='summary-line'>
							<p className='Label'>Tax ({orderFormData.taxRate * 100}%):</p>
							<p className='Value'>${tax.toFixed(2)}</p>
						</div>
						<div className='summary-line'>
							<p className='Label'><strong>Total:</strong></p>
							<p className='Value'><strong>${total.toFixed(2)}</strong></p>
						</div>
					</div>
				</div>

				<div className='order'>
					<input
						placeholder="Full Name"
						name="name"
						value={orderFormData.name}
						onChange={handleInputChange}
						required />
					<input
						placeholder="Shipping Address"
						name="address"
						value={orderFormData.address}
						onChange={handleInputChange}
						required />
					<input
						placeholder="Email Address"
						type="email"
						name="email"
						value={orderFormData.email}
						onChange={handleInputChange}
						required />
					<textarea
						placeholder="Message"
						name="message"
						value={orderFormData.message}
						onChange={handleInputChange} />
					<button type="submit">Submit Order</button>
				</div>
			</form>
			<p className="order-subtitle">{status}</p>
			<Footer />
		</div>
	);
};

export default Order;
